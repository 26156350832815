import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export interface ToggleStage<T = boolean> {
    value: T;
    colorClass?: string;
    color?: string;
    icon?: string;
    tooltip?: string;
    grow?: number;
}

const TWO_WAY_TOGGLE_STAGES: ToggleStage[] = [
    {value: false},
    {value: true, colorClass: 'brand-3'},
];

export const TWO_WAY_W_DANGER_TOGGLE_STAGES: ToggleStage[] = [
    {value: false, colorClass: 'danger', icon: 'times'},
    {value: true, colorClass: 'brand-3', icon: 'check'},
];

const THREE_WAY_TOGGLE_STAGES: ToggleStage[] = [
    {value: false, colorClass: 'danger', icon: 'times'},
    {value: null},
    {value: true, colorClass: 'brand-3', icon: 'check'},
];

@Component({
    selector: 'app-toggle',
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ToggleComponent), multi: true},
    ],
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent<T = boolean> implements ControlValueAccessor {
    @Input() size: 'sm' | 'md' | 'lg';
    @Input() label: string;
    @Input() isThreeWay = false;
    @Input() disabled = false;
    @Input() acceptUndefined = false;
    @Input() stages: ToggleStage<T>[];

    valueIndex = 0;
    progress = 0;
    private _value: T;

    get value() {
        return this._value;
    }

    set value(val: T) {
        this._value = val;
        this.valueIndex = this.getValueIndex();
        if (this.stages.some(x => x.grow)) {
            const sizes = this.stages.map(s => s.grow || 1);
            const total = sizes.reduce((acc, l) => acc + l, 0);
            this.progress = sizes.slice(0, this.valueIndex).reduce((acc, l) => acc + l, 0) / (total - sizes[sizes.length - 1]) * 100;
        } else {
            this.progress = this.valueIndex / (this.stages.length - 1) * 100;
        }
    }

    getValueIndex(val: T = this._value) {
        let vi = this.stages.findIndex(s => s.value === val);
        if (vi < 0 && [1, '1'].includes(val as any)) vi = this.stages.findIndex(s => s.value === true);
        if (vi < 0 && [0, '0'].includes(val as any)) vi = this.stages.findIndex(s => s.value === false);
        if (vi < 0) vi = this.stages.findIndex(s => s.value === null);
        if (vi < 0) vi = this.stages.findIndex(s => s.value === undefined);
        if (vi < 0) vi = this.stages.findIndex(s => s.value === false);
        return vi;
    }

    ngOnInit() {
        if (!this.stages) this.stages = (this.isThreeWay ? THREE_WAY_TOGGLE_STAGES : TWO_WAY_TOGGLE_STAGES) as ToggleStage<T>[];
        this.valueIndex = this.getValueIndex();
    }

    writeValue(value: T) {
        if (this.acceptUndefined || value !== undefined) {
            this.value = value;
        }
    }

    propagateChange = (_: any) => {
    };

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn) {
    }

    toggle(event?) {
        if (event) event.stopPropagation();

        if (this.disabled) return;

        const ci = this.getValueIndex();
        this.value = this.stages[((ci > -1 ? ci : 0) + 1) % this.stages.length].value;

        this.propagateChange(this.value);
    }

    set(val: T, event?) {
        event?.stopPropagation();
        this.value = val;
        this.propagateChange(this.value);
    }
}
