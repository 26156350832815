<div *ngIf="stages"
     class="toggle toggle{{size ? '-' + size : ''}} bg-{{stages[valueIndex].colorClass}}"
     [style.background-color]="stages[valueIndex].color"
     [class.disabled]="disabled"
     [class.multi-way]="stages.length > 2"
     [class.mr-2]="label"
     (click)="toggle($event)">
    <div class="knob-area">
        <div class="knob" *ngIf="stages[valueIndex] as stage" [style.left.%]="progress" [ngbTooltip]="stage.tooltip">
            <fas *ngIf="stage.icon" [i]="stage.icon" class="text-{{stage.colorClass}}" [style.color]="stage.color"></fas>
        </div>
    </div>
    <div *ngIf="stages.length > 2" class="toggle-stage-cnt">
        <div *ngFor="let stage of stages" class="toggle-stage" [style.flex-grow]="stage.grow" [ngbTooltip]="stage.tooltip" (click)="set(stage.value, $event)">
            <div class="toggle-stage-bg bg-{{stage.colorClass || !stage.color && 'neutral'}}" [style.background-color]="stage.color"></div>
            <far class="toggle-icon" *ngIf="stage.icon" [i]="stage.icon"></far>
        </div>
    </div>
</div>
<span *ngIf="label; else content" (click)="toggle($event)" [class.small]="size == 'sm'" [class.text-muted]="disabled">{{label}}</span>
<ng-template #content><span (click)="toggle($event)" class="ml-2 hide-empty"><ng-content></ng-content></span></ng-template>
